import { Layout } from 'components/global'
import { Link } from 'gatsby'
import React from 'react'

export default function ThankYouPage() {
  return (
    <Layout>
      <div className="mx-auto bg-purple flex flex-col items-center justify-center text-white h-[50vh] space-y-4">
        <div className="mx-auto text-center w-[90%] max-w-[850px]">
          <h1 className="uppercase text-[22px] text-center leading-[30px] md:text-[30px]">
            Thank you for applying to the Music Play Day Scheme for Disney’s
            ALADDIN The Musical.
          </h1>
          <p className="font-semibold text-[18px]	text-center">
            If your application is successful, you will be contacted by a
            representative of Disney Theatrical Group.{' '}
          </p>
          <Link to="/">
            <button
              type="button"
              className="w-full max-w-[300px] rounded-[25px] font-black	 bg-gold text-purple py-4 px-12 uppercase hover:bg-black transition-all hover:text-white"
            >
              <span>Back to homepage</span>
            </button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}
